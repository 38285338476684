import React from 'react';
import { Helmet } from "react-helmet";
import Footer from './Footer';

const Layout = ({ children, seo }) => {

  const renderHelmet = () => {
    if (seo) {
      return (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{seo.title}</title>
          <meta name="description" content={seo.description} />
          <link rel="canonical" href={seo.canonical} />
        </Helmet>
      )
    }
  }

  return (
    <>
      {renderHelmet()}
      <div className="bg-gray-50">
        <div className="relative overflow-hidden">
          <main>
            {children}
          </main>
          <Footer />
        </div>
      </div >
    </>
  )
}

export default Layout;